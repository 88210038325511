<template>
    <form class="form-group" v-if="mediaInformation">
        <h5>Profile Media</h5>

        <div class="form-floating mt-2">
            <select class="form-select" v-model="editMediaInformation.MediaType">
                <option selected disabled hidden :value="null">(Please Select...)</option>
                <option v-for="type in mediaType" :value="type.Type">
                    {{type.Name}}
                </option>
            </select>    
            <label>Type</label>
        </div>
        <div class="form-floating mt-2" v-if="editMediaInformation.MediaType == 'HlsSource'">
            <select class="form-select" v-model="editMediaInformation.Uri">
                <option selected disabled hidden :value="null">(Please Select...)</option>
                <option v-for="video in m3u8Videos" :value="video.TractusM3U8Url">
                    {{video.Name}}
                </option>
            </select>    
            <label>Video</label>
        </div>

        <div class="form-floating mt-2">
            <input  :disabled="submitting" 
                class="mb-2 form-control"
                v-model="editMediaInformation.Uri" />
            <label>URL/Path</label>
        </div>

        <div>
            <small>
                Video Start Time
            </small>
        </div>

        <div>
            <div class="row">
                <div class="col-auto">
                    <div class="form-floating mt-2">
                        <input  :disabled="submitting" 
                            class="mb-2 form-control"
                            type="number"
                            min="0"
                            max="23"
                            v-model="startTimeHours" />
                        <label>HH</label>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-floating mt-2">
                        <input  :disabled="submitting" 
                            class="mb-2 form-control"
                            type="number"
                            min="0"
                            max="59"
                            v-model="startTimeMinutes" />
                        <label>MM</label>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-floating mt-2">
                        <input  :disabled="submitting" 
                            class="mb-2 form-control"
                            type="number"
                            min="0"
                            max="59"
                            v-model="startTimeSeconds" />
                        <label>SS</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating mt-2">
                        <input  :disabled="submitting" 
                            class="mb-2 form-control"
                            type="number"
                            min="0"
                            v-model="editMediaInformation.StartAtSeconds" />
                        <label>Start Time (Seconds)</label>
                    </div>
                </div>
            </div>
        </div>




        <div class="form-floating mt-2">
            <input  :disabled="submitting" 
                    class="mb-2 form-control"
                    v-model="editMediaInformation.Title" />
            <label>Title</label>
        </div>

        <label>
            Media Thumbnail/Cover Photo
        </label>

        <div class="form-floating mt-2">
            <input  :disabled="submitting" 
                    class="mb-2 form-control"
                    v-model="editMediaInformation.ThumbnailUri" />
            <label>Title</label>
        </div>

        <file-picker ref="picker" 
                @fileSelected="onFileSelected" 
                :showCode="show.Code"
                type="image"
                :imagePath="editMediaInformation.ThumbnailUri"/>

        <div class="btn-group btn-group-sm">
            <button type="button" class="btn btn-primary" @click="onChoose">
                Choose File...
            </button>
            <button type="button" class="btn btn-warning" @click="editMediaInformation.ThumbnailUri = null">
                Clear
            </button>
        </div>
        <img class="img-fluid" 
                :src="editMediaInformation.ThumbnailUri" />

    </form>
</template>
<script>

import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default{
    props: ['show','mediaInformation'],
    mixins: [
        Token, 
        Common
    ],

    computed:{
        m3u8Videos(){
            return this.videos.filter(x=> x.TractusM3U8Url != null && x.TractusM3U8Url != '' );
        },

        startTimeSeconds: {
            get() {
                if(this.mediaInformation.StartAtSeconds != 0) {
                    return this.mediaInformation.StartAtSeconds % 60 || 0;
                }

                return 0;
            },
            set(newValue) {
                let hours = this.startTimeHours;

                let minutes = this.startTimeMinutes;

                let seconds = parseInt(newValue);;

                console.log(hours, minutes, seconds);
                Vue.set(this.mediaInformation, 'StartAtSeconds', (minutes * 60) + (hours * 3600) + seconds);
            }
        },

        startTimeMinutes: {
            get() {
                if(this.mediaInformation.StartAtSeconds != 0) {
                    return Math.floor((this.mediaInformation.StartAtSeconds % 3600) / 60) || 0;
                }

                return 0;
            },
            set(newValue) {
                let hours = this.startTimeHours;

                let minutes = parseInt(newValue);

                let seconds = this.startTimeSeconds;

                console.log(hours, minutes, seconds);
                Vue.set(this.mediaInformation, 'StartAtSeconds', (minutes * 60) + (hours * 3600) + seconds);
            }
        },

        startTimeHours: {
            get() {
                if(this.mediaInformation.StartAtSeconds != 0) {
                    return Math.floor(this.mediaInformation.StartAtSeconds / 3600) || 0;
                }

                return 0;
            },
            set(newValue) {
                let hours = parseInt(newValue);;

                let minutes = this.startTimeMinutes;

                let seconds = this.startTimeSeconds;

                console.log(hours, minutes, seconds);

                Vue.set(this.mediaInformation, 'StartAtSeconds', (minutes * 60) + (hours * 3600) + seconds);
            }
        }
    },

    watch: {
        'mediaInformation.Uri': function(newVal, oldVal) {
            if(newVal.startsWith('https://www.youtube.com/watch?')) {
                //https://www.youtube.com/embed/JL9w0jvWS2g?autoplay=1

                let embedPath = newVal.replace('/watch?v=', '/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.mediaInformation.Uri = embedPath;

                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            } else if (newVal.startsWith("https://youtu.be/")) {
                // https://youtu.be/X-SMvEmL1Xg
                let embedPath = newVal.replace('https://youtu.be/', 'https://www.youtube.com/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.mediaInformation.Uri = embedPath;

                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            } else if (newVal.startsWith('https://www.twitch.tv/')) {
                let channelName = newVal.split('/').pop();

                let embedPath = `https://player.twitch.tv/?volume=0.5&!muted&channel=${channelName}`;

                this.mediaInformation.Uri = embedPath;
                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            }
        },
        'editMediaInformation.MediaType':function(newVal, oldVal){
            if(newVal == 'HlsSource'){
                this.loadVideos();
            }
        }
    },

    data() {

        return {
            editMediaInformation: null,
            mediaType: [
                {Type:null, Name:"None"},
                {Type:"LiveWebSource", Name:"Web Source (YouTube, Twitch, etc.)"},
                {Type:"HlsSource", Name:"HLS (M3U8) Source"},
                {Type:"MuxLive", Name:"Mux Live Stream"},
                {Type:"MuxAsset", Name:"Mux Recording"},
            ],
            videos: []
            // editShow:null,
            // editvendorCategories: null,
            // validCode: true
        }
    },

    created: function(){
        // this.editMediaInformation = this.cloneObject(this.mediaInformation);
        this.editMediaInformation = this.mediaInformation;
        if(!this.editMediaInformation.StartAtSeconds) {
            Vue.set(this.editMediaInformation, "StartAtSeconds", 0);
        }  
    },

    methods:{
        async loadVideos(){
            this.videos = [];
        },
        onFileSelected(fileName){
            this.editMediaInformation.ThumbnailUri = fileName;
            // this.editAttendee.ContactInfo.PhotoUrl = fileName;
        },
        onChoose(){
            // console.log(this.show);
            this.$refs.picker.openPicker();
        },
    }

}
</script>