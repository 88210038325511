<template>
    <loading v-if="submitting">
    </loading>

    <div v-else>
        <nav-bar></nav-bar>
        <vue-title :title="'All Short URLs'"></vue-title>
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col">
                    <input class="form-control form-control-sm"
                           type="search"
                           v-model="searchFilter"
                           placeholder="Search...">
                </div>
                <div class="col-auto">
                    
                    <div class="btn-group">
                        <button class="btn btn-outline-success btn-sm"
                                @click="exportFilteredList">
                            Export
                        </button>
                        <button class="btn btn-outline-primary btn-sm"
                                @click="build">
                            Refresh
                        </button>
                        <button class="btn btn-outline-primary btn-sm"
                                @click="addCandidateRow">
                            Add...
                        </button>                  

                    </div>
                </div>
            </div>

            
            <div class="table-responsive">
                <table class="table table-sm table-striped short-url-list">
                    <thead>
                        <tr>
                            <th class="w-auto">
                                Show
                            </th>
                            <th class="w-auto">
                                URL Code
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Short URL
                            </th>
                            <th>
                                Destination
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="5">
 
                            </td>
                        </tr>
                        <template v-for="item in filteredResults">
                            <tr v-if="item._IsNew">
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.ShowCode"
                                               placeholder="Show Code">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.UrlCode"
                                               placeholder="URL Code">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.Description"
                                               placeholder="Description">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               readonly
                                               :value="`u.tractus.ca/${item.ShowCode || ''}/${item.UrlCode || ''}`">
                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control form-control-sm"
                                               v-model="item.TargetUrl"
                                               placeholder="Target URL">

                                        <button class="btn btn-success btn-sm"
                                            @click="saveChangesToLink(item)">
                                            <i class="bi bi-floppy"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-else>
                                <td>
                                    {{ item.ShowCode }}
                                </td>
                                <td>
                                    {{ item.UrlCode }}
                                </td>                            
                                <td>
                                    <input v-model="item.Description"
                                            class="form-control form-control-sm">
                                </td>
                                <td>
                                    <div class="input-group">
                                        <button class="btn btn-primary btn-sm"
                                                @click="copyShortUrlToClipboard(item)">
                                            <i class="bi bi-share-fill"></i>
                                        </button>

                                        <a :href="`https://u.tractus.ca/${item.ShowCode}/${item.UrlCode}`" 
                                            class="btn btn-outline-primary btn-sm"
                                            target="_blank">
                                            <i class="bi bi-upload"></i>
                                        </a>

                                        <input readonly
                                            :value="`u.tractus.ca/${item.ShowCode}/${item.UrlCode}`"
                                            class="form-control form-control-sm"
                                            @click="copyShortUrlToClipboard(item)">


                                    </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <button class="btn btn-outline-primary btn-sm"
                                                @click="copyLongUrlToClipboard(item)">
                                            <i class="bi bi-share-fill"></i>
                                        </button>

                                        <a :href="item.TargetUrl" 
                                            class="btn btn-outline-primary btn-sm"
                                            target="_blank">
                                            <i class="bi bi-upload"></i>
                                        </a>

                                        <input v-model="item.TargetUrl"
                                            class="form-control form-control-sm">
                                        <button class="btn btn-danger btn-sm"
                                            @click="deleteLink(item)">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                        <button class="btn btn-success btn-sm"
                                                @click="saveChangesToLink(item)">
                                            <i class="bi bi-floppy"></i>
                                        </button>

                                    </div>
                                </td> 
                            </tr>
                        </template>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { 
    getApiUrl
} from './environment.hci'
import Toastify from 'toastify-js'
import ExcelJS from 'exceljs'

export default {
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            shortUrlList: [],
            searchFilter: '',
        }
    },

    computed: {
        filteredResults() {
            let result = this.shortUrlList;

            if(this.searchFilter) {
                let searchTest = new RegExp(this.searchFilter, 'i');
                result = result.filter(x => searchTest.test(x.ShowCode) || searchTest.test(x.UrlCode) || x._IsNew);
            }

            return result;

        },
    },  

    methods: {
        async exportFilteredList() {
            let currentFilter = this.filteredResults;

            let book = new ExcelJS.Workbook();
            let sheet = book.addWorksheet('ShortUrls');

            let rows = [];

            let columns = [
                'Description',
                'ShortUrl',
                'Destination'
            ];

            sheet.addRow(columns);

            for(let i = 0; i < currentFilter.length; i++) {
                let row = currentFilter[i];

                const excelRow = sheet.addRow([
                    row.Description || '',
                    `https://u.tractus.ca/${row.ShowCode}/${row.UrlCode}`,
                    row.TargetUrl
                ]);

                excelRow.getCell(2).value = {
                    text: `https://u.tractus.ca/${row.ShowCode}/${row.UrlCode}`,
                    hyperlink: `https://u.tractus.ca/${row.ShowCode}/${row.UrlCode}`,
                };

                excelRow.getCell(2).font = { color: { argb: 'FF0000FF'}, underline: true };

                excelRow.getCell(3).value = {
                    text: row.TargetUrl,
                    hyperlink: row.TargetUrl,
                };

                excelRow.getCell(3).font = { color: { argb: 'FF0000FF'}, underline: true };
            }

            sheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength + 5;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });

            for(let i = 0; i < columns.length; i++) {
                sheet.getCell(`${String.fromCharCode('A'.charCodeAt() + i)}1`).font = {
                    bold: true
                };
            }

            sheet.autoFilter = {
                from: 'A1',
                to: `${String.fromCharCode('A'.charCodeAt() + columns.length - 1)}1`
            };

            console.log(sheet.autoFilter);

            let buffer = await book.xlsx.writeBuffer();

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            let blob = new Blob([buffer], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            link.download = `shorturl_export.xlsx`;

            link.click();
            document.removeChild(link);            
        },

        addCandidateRow() {
            this.shortUrlList.splice(0, 0, {
                ShowCode: '',
                UrlCode: '',
                Description: '',
                _IsNew: true,
                TargetUrl: '',
            });
        },

        async deleteLink(item) {
            if(!confirm("Are you sure you want to delete this short URL?")) {
                return;
            }

            this.submitting = true;
            try {
                await this.tryDelete(`/api/shorturl/${item.ShowCode}/${item.UrlCode}`);
                Toastify({
                    text: "Item deleted.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();
                
                this.submitting = false;
                this.build();
            } catch(ex) {
                Toastify({
                    text: "Update failed. See console for details.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();            

                this.build();
            }
        },        

        async saveChangesToLink(item) {
            this.submitting = true;
            try {
                await this.tryPost('/api/shorturl', JSON.stringify([item]), 'application/json');
                item._IsNew = false;
                Toastify({
                    text: "Saved.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();
                
                this.submitting = false;
            } catch(ex) {
                Toastify({
                    text: "Update failed. See console for details.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();            

                this.build();
            }
        },

        copyShortUrlToClipboard(item) {
            let shortUrl = `https://u.tractus.ca/${item.ShowCode}/${item.UrlCode}`;
            navigator.clipboard.writeText(shortUrl);
            Toastify({
                text: "Copied link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();            
        },

        copyLongUrlToClipboard(item) {
            let shortUrl = item.TargetUrl;
            navigator.clipboard.writeText(shortUrl);
            Toastify({
                text: "Copied destination link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();            
        },

        async build() {
            this.submitting = true;
            this.errors = [];

            try {
                let result = await this.tryGet('/api/shorturls');
                
                this.shortUrlList = result.Result;
            } catch(ex) {
                this.errors = [ex];
                this.domains = [];
            }

            this.submitting = false;
        },

        onPasteWindow(e) {
            let activeElement = document.activeElement;

            if(activeElement && activeElement.tagName == 'INPUT') {
                return;
            }

            let clipboardData = e.clipboardData || window.clipboardData;

            if(!clipboardData) {
                return;
            }

            let pastedContent = clipboardData.getData("text");

            e.preventDefault();
            console.log(pastedContent);

            pastedContent = pastedContent.split('\n').map(line => line.trim()).filter(line => line && line.indexOf("https://") != -1);

            console.log(pastedContent);

            for(let i = 0; i < pastedContent.length; i++) {
                this.shortUrlList.splice(0, 0, {
                    ShowCode: '',
                    UrlCode: '',
                    Description: '',
                    _IsNew: true,
                    TargetUrl: pastedContent[i],
                });
            }
        },
    },

    beforeDestroy() {
        window.removeEventListener('paste', this.onPasteWindow);
    },

    created() {
        window.addEventListener("paste", this.onPasteWindow);
        this.build();
    },
}
</script>