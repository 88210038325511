<!-- DateTimePicker.vue -->
<template>
    <div>
      <label for="datetime">Select Date and Time:</label>
      <input
        type="datetime-local"
        id="datetime"
        :value="datetimeLocalValue"
        step="0.001"
        @input="updateDatetime" />

        {{ value }} | {{ datetimeLocalValue }}
    </div>
  </template>
  
  <script>
  export default {
    name: 'DateTimePicker',
    props: {
      // Accept ISO string with 'Z' at the end
      value: {
        type: String,
        required: true,
      },
    },
    computed: {
      // Convert ISO string (UTC) to local date/time string for the input value
      datetimeLocalValue() {
        return this.formatDateTimeLocal(this.value);
      },
    },
    methods: {
      // Format UTC ISO string into local 'YYYY-MM-DDTHH:mm:ss.sss' string
      formatDateTimeLocal(value) {
        if (!value) return '';
        const date = new Date(value); // Parses the ISO string into a Date object
        // Extract local date components
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        const milliseconds = ('00' + date.getMilliseconds()).slice(-3);
        // Format the date string for the input value
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
      },
      // Convert local date/time string from input back to ISO string with 'Z'
      updateDatetime(event) {
        const value = event.target.value; // e.g., '2024-10-30T15:04:19.316'
        if (!value) return;
        // Create a Date object from the local date/time string
        const localDate = new Date(value);
        // Convert the Date object to an ISO string in UTC
        const isoString = localDate.toISOString(); // Includes 'Z' at the end
        // Emit the ISO string
        this.$emit('input', isoString);
      },
    },
  };
  </script>
  